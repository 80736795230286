@import "./variables.scss";

@media (max-width: 992px) and (min-width: 577px) {
  .product-page {
    .product-add-sticky {
      order: 0;
      // display: flex;
      justify-content: center;
      width: 363px;
      margin: auto;
      margin-bottom: 75px;

      button.w-100 {
        width: 248px !important;
        display: block;
        margin: auto;
      }

      .old-price {
        right: 24px;
      }
    }
  }
  .product-page .product-page-content {
    max-width: initial;
    padding-left: 0px;
    width: 100%;
  }
  .cards-wrapper {
    justify-content: center;
  }
}
