@import "./variables.scss";

@media (max-width: 1367px) {
  .custom-table {
    .custom-table-sum {
      font-size: 15px;
    }
  }

}

@media (max-width: 1365px) {
  .header-bar h1,
  .header-bar .h1 {
    font-size: 28px;
  }
  .sidebar {
    position: fixed;
    z-index: 1000;
    left: -100%;
    transition: .5s;
    min-width: 298px;

    .upper-sidebar > div:nth-child(1) {
      position: relative;
      top: -23px;
      left: 73px;
      margin-bottom: 13px;
    }

    .sidebar-toggler {
      display: block;
      left: 20px;
      top: 22px;
      transform: translateY(50%);
      background-color: transparent;
      transition: .5s;
      // position: relative;
      height: 20px;
      width: 30px;
      padding: 0px;

      div {
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0px;
        background-color: black;
        transition: 0.5s;
        transform-origin: 1px center 0px;

        &:first-of-type {
          top: 0%;
        }

        &:nth-of-type(2) {
          top: 50%;
          transform: translateY(-50%);
          transition: transform 5s, background-color 0.2s;
        }

        &:last-of-type {
          bottom: 0%;
        }
      }

      &.open {
        transition: .5s;

        div {
          background-color: white;
          $time: .5s;

          &:first-of-type {
            transform: rotate(45deg);
            top: -1px;
            animation-name: hiding-toggler;
            animation-duration: $time;
          }

          &:nth-of-type(2) {
            background-color: transparent;
            left: -100%;
            transition: left 1.5s, background-color 0.5s;
          }

          &:last-of-type {
            bottom: -1px;
            transform: rotate(-45deg);
            // transition-delay: animation 5s;
            animation-name: hiding-toggler;
            animation-duration: $time;
          }
        }

        @keyframes hiding-toggler {
          0% {
            background-color: black;
          }
          50% {
            background-color: transparent;
          }
          80% {
            background-color: transparent;
          }
          100% {
            background-color: white;
          }
        }
      }
    }

    &.open {
      left: 0px;
    }
  }
  // .product-list {
  // .product-wrapper {
  //   flex-basis: 23%;
  // }
  .counter-cart-wrapper {
    flex-basis: 0%;
    padding-top: 2px;
    // .counter-wrapper {
    //   width: 275px;
    // }
    > form {
      width: fit-content;

      .add-product {
        width: fit-content;
        margin: auto;
      }
    }
  }
  // .show-accesories {
  //   left: 4px;
  // }
  // }
  .main-content-wrapper > .scrollbar-container {
    padding-left: 25px;
    padding-right: 25px;
  }
  // .custom-table-sum {
  //   min-width: 105px;
  //   max-width: 105px;
  // }
  .product-page {
    padding-left: 0px;
    padding-right: 0px;

    .product-page-content {
      width: 857px;
      padding-right: 65px;

      .accordion-swiper-container {
        .swiper {
          min-width: initial;
        }
      }
    }

    .product-add-sticky {
      width: 283px;

      .product-sticky-lower {
        padding: 20px 14px;
      }

      .old-price {
        right: 3px;
        max-width: 58px;
      }
    }
  }
  .product-sticky-lower {
    padding: 20px 14px;
  }
}