@import "./variables.scss";

@media (max-width: 450px) {
  .header-bar h1,
  .header-bar .h1 {
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.76px;
  }
}
