@import "./variables.scss";
@media (max-width: 1200px) and (min-width: 993px) {
  .accordion .product-wrapper .counter-cart-wrapper {
    width: auto;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    flex-basis: initial;

    form {
      width: auto;
      margin: auto;

      > div {
        flex-wrap: nowrap;
      }
    }
  }
}
