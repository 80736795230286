@import "./variables.scss";
@media (max-width: 385px) {
  .product-wrapper .counter-cart-wrapper {
    .counter-wrapper {
      width: initial;
    }

    .add-product {
      width: initial;
    }
  }
}
