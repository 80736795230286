.custom-table {
  * {
    font-size: 12px;
  }

  .h3 {
    font-size: 18px;
    line-height: 28px;
  }

  .form-check-input {
    background-image: url("/../public/img/checkbox-outline.svg");
    height: 25px;
    width: 25px;

    &:checked {
      background-image: url("/../public/img/checkbox-done.svg");
    }
  }

  .summary-table-offer {
    border-color: $blue;

  }

  $top: -22px;

  .custom-table-row:first-of-type:not(.offer) {

    .custom-table-price {
      @include custom-table-header($top);
    }

    .custom-table-counter {
      @include custom-table-header($top);
    }

    .custom-table-sum {
      @include custom-table-header($top);
    }
  }

  .offer.custom-table-row:first-of-type {
    .product:first-of-type {
      .custom-table-price {
        @include custom-table-header($top);
      }

      .custom-table-counter {
        @include custom-table-header($top);
      }

      .custom-table-sum {
        @include custom-table-header($top);
      }
    }


  }

  .custom-table-price {
    padding: 10px;
    flex-grow: 1;
  }

  .custom-table-sum {
    font-size: 18px;
    padding: 10px;
    min-width: $sum-table-width;
    flex-basis: $sum-table-width;
    flex-grow: 1;
    font-weight: bold;
  }

  .custom-table-row {
    display: flex;
    // border: 1px solid black;
    border-bottom: 1px solid #e6e6e6;
    border-left: 1px solid black;

    &.offer {
      border-color: $blue;

      .product {
        background-color: $white;
        border-bottom: 1px solid #e6e6e6;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #e6e6e6;
          padding: 20px;
          word-wrap: anywhere;
        }

        @media (min-width: 992px) {
          width: 100%;

        }
      }

      @media (min-width: 992px) {
        .product:last-of-type {
          padding-bottom: 15px;
        }
      }


      .custom-table-delete {
        border-color: $blue;
      }
    }

    > &:first-of-type {
      border-top: 1px solid black;
      border-radius: 10px 10px 0px 0px;
      //border-bottom: 0px;

      > div:last-of-type {
        border-radius: 0px 10px 0px 0px;
      }
    }

    > div:last-of-type {
      border-right-color: #141414;
    }

    > &:first-of-type {
      border-radius: 10px 10px 0px 0px;
      //border-bottom: 0px;
    }

    .custom-table-delete {
      padding: 10px;
    }

    &:last-of-type {
      border-bottom: 1px solid black;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      // flex-basis: 10.1%;
      border-right: 1px solid #e6e6e6;
      padding: 20px;
      word-wrap: anywhere;
    }


    .custom-table-description {
      flex-basis: 300px;
      flex-grow: 4;
      gap: 15px;
      min-width: 400px;
      justify-content: start;

      .image {
        width: 25.3%;
        align-self: start;

        .relative-padding {
          padding-bottom: 64.25%;
          max-width: 255px;
          min-height: 80px;
        }

        img {
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 84.7%;
      }
    }

    .custom-table-availabilty {
      flex-basis: 140px;

      div {
        position: relative;

        &::after {
          $height: 7px;
          height: $height;
          width: $height;
          border-radius: 50%;
          content: "";
          position: absolute;
          left: -11px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.available::after {
          background-color: $success;
        }

        &.later::after {
          background-color: $warning;
        }
      }
    }

    .custom-table-price {
      flex-basis: $price-table-width;
      //max-width: $price-table-width;
      text-align: center;

      .price {
        font-weight: bold;
        font-size: 14px;
      }
    }

    .custom-table-counter {
      min-width: 160px;
    }

    &.offer {
      position: relative;
      border-left: 1px solid $blue;

      border-bottom: 1px solid $blue;
      border-top: 1px solid $blue;

      > div:last-of-type {
        border-right: 1px solid $blue;
      }

      @media (min-width: 993px) {
        &:last-of-type {
          .offer-name {
            border-bottom-left-radius: 8px;
          }
        }
      }


      &--last {
        padding-bottom: 15px;
      }


      .offer-name {
        position: absolute;
        background-color: $blue;
        left: 0;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 2.5px 0;
        font-size: 10px;
        @media (max-width: 992px) {
          top: 0;
        }
        @media (min-width: 993px) {
          bottom: 0;
        }
      }

    }

  }

  .equal-cols {
    > div {
      &:first-of-type {
        height: 48px;

        &:not(.offer--mobile) {

          border-top: 0px;
        }

        .custom-table-col {
          border-top: 1px solid;
          font-weight: bold;
          letter-spacing: -0.12px;
          border-color: #141414;

          &:first-of-type {
            border-radius: 10px 0px 0px 0px;
          }

          &:last-of-type {
            border-radius: 0px 10px 0px 0px;
          }
        }
      }

      &:nth-of-type(2) {
        .custom-table-col {
          //border-top: 1px solid #141414;

          &:last-of-type {
            border-radius: 0px 10px 0px 0px;
          }
        }
      }

      &:last-of-type {
        > div {
          @media (min-width: 992px) {

            &:first-of-type {
              border-radius: 0px 0px 0px 10px;
            }
          }

          &:last-of-type {
            border-radius: 0px 0px 10px 0px;
          }
        }
      }
    }

    .buttons-col {
      min-width: 244px;

      .btn-success {
        font-size: 10px;
        height: 40px;
        padding: 8px 7px 10px;
        min-width: 137px;
      }
    }

    .offer--mobile {
      border-color: $blue;
      border-style: solid;
      border-left-width: 1px;
      border-right-width: 1px;

      &:first-of-type {
        border-top-width: 1px;
      }

    }

    .custom-table-row.offer-name--mobile {
      background-color: $blue;
      padding: 10px;
      color: white;
      justify-content: center;
      align-items: center;
    }

    // &.with-bordered-headers {
    //   > div:first-of-type {
    //     border-radius: 0px 10px 0px 0px;
    //   }

    // (custom-table row) -->
    // > div:first-of-type {
    //   // (custom-table-col) -->
    //   > div:first-of-type {
    //     @include custom-table-header-border($top, NAZWA);

    //     &::after {
    //       border-radius: 10px 0px 0px 0px;
    //       border-left: 1px solid black;
    //     }
    //   }
    //   > div:nth-of-type(2) {
    //     @include custom-table-header-border($top, LICZBA);
    //   }
    //   > div:nth-of-type(3) {
    //     @include custom-table-header-border($top, JEDNOSTKA);
    //   }
    //   > div:nth-of-type(4) {
    //     @include custom-table-header-border($top, NUMER ZAMÓWIENIA);
    //   }
    //   > div:nth-of-type(5) {
    //     @include custom-table-header-border($top, DATA ZAMÓWIENIA);
    //     &::after {
    //       border-radius: 0px 10px 0px 0px;
    //     }
    //   }
    // }
    &.with-header-icons > div:first-of-type > div::after {
      padding: 0px 31px;
    }
  }

  &.with-subtable {
    .custom-table-row {
      &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: unset;
      }
    }
  }

  .summary {
    border: 1px solid black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: unset;
  }
}