.search-select {
  position: relative;


  .wrapper {
    display: flex;
    border-radius: 5px;
    height: 40px;

    input, > div {
      border-width: 1px 0 1px 1px;
      border-style: solid;
      border-color: black;
      border-end: none;
      border-radius: 5px 0 0 5px;
      background: #fff;

    }

  }



  button {

    display: flex;
    box-shadow: unset;
    align-items: center;
    height: 100%;
    background: #fff;
    padding: 0 10px;
    border: 1px solid black;
    border-radius: 0 5px 5px 0;

  }

  button:hover {
    cursor: pointer;
  }

  ul.options {
    min-width: 200px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    border: 1px solid black;
    font-size: 10px;
    display: none;
    list-style: none;
    padding: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    transform: translateY(100%);
    z-index: 2;
  }

  ul.show {
    display: block;
  }

  ul.options li {
    padding: 5px;
  }

  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover,
  ul.options li[aria-selected="true"] {
    background: $gray;
    color: #fff;
    cursor: pointer;
  }
}
