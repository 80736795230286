@import "./variables.scss";
// --> basic html styles
body {
  overflow: hidden;
}

h1 {
  font-weight: 500;
  letter-spacing: -0.96px;
  font-size: 2.625rem;
}

h2 {
  font-weight: 500;
  letter-spacing: -0.96px;
}

h3 {
  font-weight: 500;
  letter-spacing: -0.36px;
}

.h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  display: inline-block;

  .badge {
    height: 21px;
    vertical-align: middle;
    margin-bottom: 7px;
  }

  .smaller-badge {
    height: 18px;
    margin-bottom: 4px;
    padding-bottom: 1px;
    padding-top: 3px;
    margin-left: 5px;
  }
}

a {
  transition: color 0.3s;

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(255, 171, 64, 0.25);
    border-color: #ffd5a0;
  }
}

h1 {
  font-weight: 500;
  letter-spacing: -0.96px;
}

h2 {
  font-weight: 500;
  letter-spacing: -0.96px;
}

h3 {
  font-weight: 500;
  letter-spacing: -0.36px;
}

form {
  label {
    width: 100%;
  }
}

select {
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 700;
}

.form-check-input {
  background-image: url("/../public/img/radio-unchecked.svg");
  position: relative;
  left: -1px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.logo {
  height: 40px;
  width: 164px;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// <-- basic html styles
// --> modals
//
.modal-dialog {
  .modal-content {
    padding: 30px;
    font-size: 14px;

    .modal-header {
      margin-bottom: 15px;
      letter-spacing: -0.48px;
      font-size: 24px;
      font-weight: 500;
    }

    .modal-footer {
      margin-top: 34px;
    }
  }
}

// <-- modals
// --> items
.sort-table-icon {
  position: absolute;
  background-image: url("/../public/img/transfer.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  left: 15px;
  top: -37px;
  padding-right: 0px;
  z-index: 1;
}

.dropdown-item {
  line-height: 20px;
}

.product-wrapper,
.product {
  select {
    width: 118px;
    margin: auto;
    font-size: 10px;
    text-transform: uppercase;
    padding: 10px;
    padding-left: 28px;
    background-position: left 10px center;

    .dropdown-toggle {
      border-radius: 5px;
      text-align: center;
    }
  }
}

.dropdown {
  .dropdown-toggle {
    position: relative;
    font-size: inherit;

    &::after {
      transition: 0.2s;
      border: 0px;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      left: -29px;
      height: 25px;
      width: 25px;
      margin-left: 0px;
      background-image: url("/../public/img/arrow-down.svg");
      filter: invert(100);
    }

    &.black-arrow {
      &::after {
        filter: invert(0);
      }

      &:hover {
        &::after {
          filter: invert(100);
        }
      }
    }

    .dropdown-item {
      line-height: 20px;
    }
  }

  &.show {
    .dropdown-toggle {
      &::after {
        transform: translateY(50%) rotate(180deg);
      }
    }
  }
}

.arrow-after {
  .dropdown-toggle {
    &::after {
      left: 10px;
    }
  }

  &.arrow-dropdown-right {
    .dropdown-toggle {
      &::after {
        right: 10px;
        left: initial;
      }
    }
  }
}

.arrow-left {
  position: relative;

  &::before {
    @include after-icon-left(
                            ("/../public/img/arrow-down.svg"),
                    25px,
                    25px,
                    -25px
    );
  }

  &.rotate.opened::before {
    transform: translateY(50%) rotate(180deg);
  }
}

.arrow-right {
  position: relative;

  &::after {
    @include after-icon-right(
                            ("/../public/img/arrow-down.svg"),
                    25px,
                    25px,
                    10px
    );
  }

  &.rotate.opened-parent-absolute::after {
    transform: translateY(50%) rotate(180deg);
  }

  &.rotate.opened-parent-absolute::after {
    transform: translateY(50%) rotate(180deg);
  }

  &.rotate.opened::after {
    transform: translateY(50%) rotate(180deg);
  }
}

.icon-button {
  background-color: #f5f5f5;

  &:hover {
    background-color: black;

    img {
      filter: invert(0);
    }
  }
}

.btn-search {
  position: absolute;
}

.element-bg-center {
  @include element-bg(center);
}

.text-pill {
  background-color: #f6f8fa;
  padding: 0px 20px;
  border-radius: 34px;
}

.pill {
  height: 40px;
  border-radius: 20px;
  border-color: black;
}

.closed-parent {
  > div {
    display: none;
  }
}

.opened-parent {
  > div {
    display: block;
  }
}
.text-cancel{
  text-decoration: line-through;
  color: #b64d34;
}
.zoom {
  overflow: hidden;
  display: block;

  img {
    transition: 0.5s;
  }

  img:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
  }
}

.icon-action {
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 17.5px;
  width: 17.5px;
  box-sizing: border-box;
  transition: 0.3s;

  &:hover {
    filter: brightness(93%);
  }

  &.svg-40 {
    width: 40px;
    height: 40px;
  }

  // &:active {
  //   transform: scale(98.5%);
  // }
  &.rounded {
    img {
      border-radius: 50%;
    }
  }

  svg,
  img {
    border: 2px solid transparent;
    transition: 0.3s;
  }

  &:focus img,
  &:focus svg {
    border: 2px solid #cdcdcd;
  }

  &.svg-40 {
    padding: 0px;
  }
}

a {
  .icon-action {
    border: 2px solid transparent;
    transition: 0.3s;
  }
}

a:hover {
  .icon-action {
    filter: brightness(93%);
  }
}

a:focus {
  .icon-action {
    border: 2px solid #cdcdcd;
    transition: 0.3s;
  }
}

.pagination {
  font-size: 14px;
  font-weight: bold;
  margin-top: 74px;
  margin-bottom: 100px;

  .page-link {
    padding: 0px;
    padding-bottom: 3px;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .active .page-link {
    box-shadow: 0px 0px 6px #00000033;
  }

  .page-item:first-of-type,
  .page-item:last-of-type {
    position: relative;

    a {
      background-color: transparent;
      border: transparent;
      position: relative;
      z-index: 2;
      color: transparent;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 41.4px;
      height: 41.4px;
      background-image: url("/../public/img/pagination-back.svg");
      background-repeat: no-repeat;
      background-position: center;
      z-index: 0;
      transition: 0.3s;
    }

    &:focus::after {
      border: 2px solid #cdcdcd;
      border-radius: 50%;
    }

    &:hover::after {
      transform: scale(0.98);
    }
  }

  .page-item:last-of-type {
    &::after {
      background-image: url("/../public/img/pagination-next.svg");
      left: 10px;
      right: initial;
    }

    &:focus::after {
      border: 2px solid #cdcdcd;
      border-radius: 50%;
    }

    // &:hover::after {
    //   transform: scale(0.99);
    // }
  }
}

.relative-padding {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  min-height: 99px;

  * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.smaller-badge {
  height: 18px;
  margin-bottom: 4px;
  padding-bottom: 1px;
  padding-top: 3px;
  margin-left: 5px;
}

.search {
  display: block;
  font-size: 12px !important;
  font-weight: bold;
  padding-bottom: 17px;
  border-radius: 20px !important;

  .input-group-text {
    position: absolute;
    z-index: 20;
    width: 20px;
    height: 20px;
    padding: 0px;
    top: 9px;
    left: 13px;
    background-color: transparent;
    border: 0px;
  }
}

// <-- items
// --> components
.ps__rail-y {
  width: 1px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 7px;
  cursor: pointer;
}

.ps__thumb-y {
  width: 4px;
}

.product-wrapper {
  padding: 20px 8px;
  box-shadow: 0px 0px 6px #00000026;
  margin-bottom: 20px;
  background-color: white;

  .cart-badge {
    position: absolute;
    right: 0;
    top: 0;
    width: 20.4px;
  }

  .relative-padding {
    padding-bottom: 83%;

  }

  .image-col {
    // width: 119px;
    width: 139px;

    img {
      width: 100%;
      object-fit: cover;
    }

    .availability {
      position: relative;
      line-height: 15px;

      &::after {
        content: "";
        position: absolute;
        bottom: -9px;
        height: 5px;
        width: 100%;
        left: 0px;
      }

      &.lack::after {
        background-color: #9a9a9a;
      }

      &.withdrawn::after {
        background-color: #141414;
      }

      &.to-order::after {
        background-color: #fff840;
      }

      &.new::after {
        background-color: #64e277;
      }
    }
  }

  .product-description {
    padding-right: 30px;

    h3 {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 10px;
      display: inline;
    }

    .badge {
      // margin-top: 3px;
      position: relative;
      bottom: 3px;
    }

    p {
      margin-bottom: 0px;
      line-height: 16px;
    }
  }

  .counter-cart-wrapper {
    // width: 614px;
    justify-content: center;
    // padding-top: 25px;
    .counter-wrapper {
      // width: 348px;
      .counter {
        margin-left: auto;
      }
    }
  }
}

.price-wrapper {
  min-width: 128px;
  padding-top: 25px;

  .meter-price {
    width: fit-content;
    font-size: 14px;
    justify-content: center;
    min-width: 113px;
    @include center-all-above;

    .discount {
      height: 16px;
      line-height: 6px;
    }
  }

  .old-price span {
    position: relative;
    font-size: 12px;
    margin: 5px 0px;
    font-weight: 500;

    &::after {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      background-color: black;
      left: 0px;
      bottom: 43%;
    }
  }
}
.cart{
  .counter{
    height: 90px;
  }
}
.counter {
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
  width: 130px;
  height: 40px;
  align-items: center;

  button {
    background-color: transparent;
    border-radius: 5px;
    line-height: 1px;
    height: 40px;
    padding: 3px 7px;
    width: fit-content;

    &:hover {
      background-color: #212529;

      img {
        filter: invert(100);
      }
    }

    &:first-of-type {
      margin-left: -1px;
    }

    &:last-of-type {
      margin-right: -1px;
    }
  }

  input {
    border: 0px;
    background-color: transparent;
    height: 100%;

    &:focus {
      box-shadow: none;
    }
  }
}

.counter-border {
  border: 1px solid black;
}

.show-accesories:hover,
.show-accesories.opened {
  position: relative;
  cursor: pointer;
  filter: invert(100);
  transition: 0.3s;

  &::after {
    content: "";
    position: absolute;
    background-color: white;
    bottom: -7px;
    width: 199%;
    height: 30px;
    right: -31px;
    border-radius: 20px 20px 0px 0px;
    z-index: -1;
  }
}

.show-accesories.opened .show-accesories.opened:hover {
  &::before {
    left: -31px;
  }
}

.show-accesories:hover {
  &::after {
    width: 174%;
    right: -26px;
  }
}

.show-accesories.opened:hover {
  &::after {
    width: 199%;
    right: -31px;
  }
}

// .accesories-button-col{

// }
.accesories-button-wrapper {
  position: relative;
  margin-top: 8px;
  text-transform: uppercase;

  & > div {
    position: absolute;
    bottom: -14px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: -21px;
    height: 1px;
    background-color: #282828;
    left: -8px;
    width: calc(100% + 17px);
  }

  .arrow-left::before {
    bottom: 7px;
  }
}

.client-card {
  max-width: 290px;
  min-height: 160px;


}

.list-accesories {
  width: calc(100% - 21px);

  .product-wrapper {
    padding: 20px;

    h4 {
      margin-bottom: 0px;
    }

    h3 {
      font-size: 14px;
      display: inline-block;
      margin: 3px 0px;
    }

    .counter-cart-wrapper {
      padding-top: 0px;
    }
  }

  .accesories-button-wrapper::before {
    left: -21px;
    width: calc(100% + 42px);
  }

  &.second-level-accesories {
    .product-wrapper {
      .show-accesories {
        left: -59px;
      }
    }
  }

  .show-accesories {
    left: -29px;
  }

  .image-col {
    width: 84px;
    padding-left: 0px;

    .relative-padding {
      padding-bottom: 100%;
      min-height: initial;
    }
  }
}

.product-availability {
  font-size: 10px;

  &--now {
    color: $success
  }

  &--soon {
    color: $warinig
  }

  &--never {
    color: $danger
  }
}

.content-container,
.product-added {
  .tab-content {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.nav-tabs {
  border-bottom: 1px solid black;

  .nav-item {
    max-width: 187px;
    border: 1px solid black;
    border-radius: 20px 20px 0px 0px;
    height: 40px;
    height: fit-content;

    button {
      background-color: white;
      height: 40px;
      font-size: 10px;
      border-radius: 20px 20px 0px 0px;
      background-color: transparent;
      border: 0px;

      &.active,
      &:hover {
        color: white;
        background-color: black;
      }
    }
  }
}

.accordion {
  .accordion-item {
    border-left: 0px;
    border-right: 0px;

    .accordion-header {
      button {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        box-shadow: none;
        border-bottom: 0px;
        padding-top: 20px !important;
        padding-bottom: 25px !important;
      }
    }

    .accordion-body {
      padding-bottom: 55px;

      h5 {
        margin-bottom: 20px;
      }
    }
  }

  .accordion-specification {
    // > div:last-of-type {
    //   margin-top: 40px;
    // }
    > div {
      margin-bottom: 35px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  .accordion-spec-wrapper {
    > div:first-of-type {
      border-top: 1px solid #e6e6e6;
    }
  }

  .accordion-swiper-container {
    padding-right: 15px;
    width: calc(100% + 30px);

    .swiper {
      min-width: 764px;
      max-height: initial;
      max-width: initial;
      overflow: initial;

      &::after,
      &::before {
        position: absolute;
        content: "";
        top: 0px;
        height: 101%;
        width: 15px;
        background-color: white;
        left: -15px;
        z-index: 9;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        left: initial;
        right: -15px;
      }
    }
  }

  .accordion-spec {
    min-height: 38px;
    border-bottom: 1px solid #e6e6e6;

    > div {
      display: flex;
      align-items: center;
    }

    .accordion-spec-type {
      min-width: 290px;
      line-height: 30px;
      font-size: 12px;
      font-weight: bold;
    }

    span {
      vertical-align: middle;
    }

    .accordion-spec-data {
      font-size: 14px;
    }
  }

  .attachment {
    display: flex;
    height: 60px;
    align-items: center;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;

    a.btn-link {
      margin-right: 15px;

      img {
        width: 40px;
        height: 40px;
        margin-left: 3px;
      }
    }

    a {
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .video {
    .accordion-body {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;

      * {
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }

  .product-wrapper {
    // background-color: #f6f8fa;
    .product-description {
      h3 {
        font-size: 14px;
        display: inline-block;
        line-height: 16px;
        margin-bottom: 5px;
      }
    }

    .counter-cart-wrapper {
      width: 271px;
      padding-top: 0px;
    }
  }

  .list-accesories {
    margin-right: 0px !important;
    width: calc(100% - 40px);


  }

  .add-product {
    margin: auto;
    margin-top: 4px;

    button {
      width: 100%;
    }
  }

  .product-wrapper .counter-cart-wrapper .counter-wrapper {
    width: 271px;
  }
}

// <-- components
// --> wrappers
.parameters {
  div {
    line-height: 18px;
    font-size: 14px;
  }
}


// .custom-table .equal-cols .custom-table-row > div {
//   flex-basis: initial;
//   width: 20% !important;
// }
// .custom-table .custom-table-row > div {
//   flex-basis: initial;
// }
// .custom-table .custom-table-row:first-of-type {
//   border: 0px aliceblue;
// }
// .orders-tabel .equal-cols > div:first-of-type {
//   $top: -48px;
//   > div:first-of-type::after {
//     content: "NUMER ZAMÓWIENIA";
//   }
//   > div:nth-of-type(2)::after {
//     content: "NUMER ZAMÓWIENIA KLIENTA";
//   }
//   > div:nth-of-type(3)::after {
//     content: "DATA ZAMÓWIENIA";
//   }
//   > div:nth-of-type(4)::after {
//     content: "KWOTA NETTO";
//   }
//   > div:nth-of-type(5)::after {
//     content: "STATUS";
//     border-radius: 0px;
//   }
//   > div:nth-of-type(6) {
//     @include custom-table-header-border($top, PRZEWIDYWANY TERMIN DOSTAWY);
//     &::after {
//       border-radius: 0px 10px 0px 0px;
//     }
//   }
// }
.subtable-wrapper {
  border: 1px solid black;
  border-top: 0px;
}

.subtable-single-wrapper {
  .subtable-row {
    > div {
      border-right: 0px;
    }
  }
}

.subtable-row {
  display: flex;
  font-weight: bold;
  font-size: 15px;
  height: 50px;

  > div {
    width: $sum-table-width;
    padding: 10px;
    border-right: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > div:first-of-type {
    // flex-basis: 50%;
    // min-width: 262px;
    justify-content: end;
  }

  // > div:last-of-type {
  //   border-right-color: black;
  // }
  .text-pill {
    padding: 4px 15px;
  }
}

div.subtable-row:nth-child(2) > div {
  padding-bottom: 20px;
}

.subtable-single-wrapper {
  border-radius: 0px 0px 10px 10px;

  .subtable-row {
    height: 60px;

    > div {
      flex-basis: 100%;
      justify-content: center;
      font-size: 24px;
    }
  }
}

// <-- wrappers
// --> shortcuts
.cursor-pointer {
  cursor: pointer;
}

.max-1386 {
  max-width: 1386px;
}

.shadow-radius-white {
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000026;
  background-color: white;
}

.badge-circle {
  border-radius: 50%;
  padding: 0px;
  height: 17px;
  width: 17px;
  line-height: 1;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
}

.unstyled-a {
  color: black;
  text-decoration: none;
  transition: 0.3s;

  &.hover:hover {
    color: $primary;
  }
}

.no-hover:hover {
  color: inherit;
}

.no-underline {
  text-decoration: none;
}

.border-black-80 {
  border: 1px solid #141414;
}

.circle {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  &.warning {
    background-color: #e2ca64;
  }

  &.success {
    background-color: #8dc881;
  }

  &.danger {
    background-color: #b64d34;
  }

  &.small {
    width: 10px;
    height: 10px;
  }
}

.b-50 {
  border-radius: 50%;
}

.border-20 {
  border-radius: 20px;
}

.border-10 {
  border-radius: 10px;
}

.border-top-10 {
  border-radius: 10px 10px 0px 0px;
}

.box-shadow-6 {
  box-shadow: 0px 0px 6px #00000026;
}

.lh-24 {
  line-height: 24px;
}

.ls-48 {
  letter-spacing: -0.48px;
}

.fz-32 {
  font-size: 32px;
}

.fz-18 {
  font-size: 18px;
}

.fz-14 {
  font-size: 14px;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-11 {
  font-size: 11px;
}

.fz-10 {
  font-size: 10px;
}

.fz-9 {
  font-size: 9px;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.not-dot {
  list-style: none;
}

.text-grey {
  color: #686868;
}

.text-blue {
  color: $blue;
}

.text-second-black {
  color: $second-black;
}

.bg-white {
  background-color: white;
}

.gray-bg {
  background-color: $tertiary-gray;
}

.invert {
  filter: invert(100);

  &.icon-action:hover {
    filter: invert(100) brightness(100%) drop-shadow(0px 0px 2px black);
  }

  &.icon-action:focus img {
    border: transparent;
    filter: drop-shadow(0px 0px 2px black);
  }
}

// second is the same bg as to free delivery in product.js
.second-gray-bg {
  background-color: #f6f8fa;
}

.medium-gray-bg {
  background-color: #edf0f4;
}

.darker-gray-bg {
  background-color: #e5eaef;
}

.white-bg {
  background-color: white;
}

.half-gap {
  --bs-gutter-x: 0.75rem;
}

.h-40 {
  height: 40px;
}

.h-min-40 {
  min-height: 40px;
  height: initial;
}

.w-290 {
  width: 290px;
}

.w-279 {
  width: 279px;
}

.w-224 {
  width: 224px;
}

.w-137 {
  width: 137px;
}

.gap {
  gap: 15px;
}

.gap-10 {
  gap: 10px;
}

.p-30 {
  padding: 30px;
}

.ps-34 {
  padding-left: 38px !important;
}

.pe-27 {
  padding-right: 27px;
}

.px-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.ps-50 {
  padding-left: 50px;
}

.pe-50 {
  padding-right: 50px;
}

.pe-40 {
  padding-right: 40px;
}

.px-28 {
  padding-left: 28px;
  padding-right: 28px;
}

.me-27 {
  margin-right: 27px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

// <-- shortcuts

.footer {
  position: fixed;
  padding-left: $padding-left;
  bottom: 0px;
  color: white;
}

// @media (min-width: 1655px) {
//   // .subtable-row > div:nth-of-type(2) {
//   //   width: 143px;
//   // }
//   .custom-table-sum {
//     flex-basis: inherit;
//     min-width: 143px;
//     max-width: 143px;
//   }
// }
@media (min-width: 1366px) {
  .counter-wrapper {
    width: 348px;
  }
}

@media (min-width: 1366px) and (max-width: 1655px) {
  // .custom-100 {
  //   width: 100%;
  // }
  .content-container .product-wrapper .counter-cart-wrapper {
    width: 271px;
    padding-top: 0px;
  }

  .custom-250 {
    width: 250px;
  }
  .mt-custom-xl-3 {
    margin-top: 16px;
  }
}

@media (min-width: 1366px) and (max-width: 1500px) {
  .custom-table {
    .custom-table-row {
      .custom-table-checkbox {
        width: 50px;
        flex-basis: auto;
      }
    }
  }
  .content-container .scrollbar-container {
    padding: 33px 0px;

    .main-content {
      padding: 50px 15px 20px 15px;
    }
  }
}
