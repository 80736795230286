.custom-select {
  position: relative;
  width: 118px;

  button {

    display: flex;

    align-items: center;
    height: 100%;
    background: #fff;
    width: 118px;
    line-height: 1.5;
    font-size: 10px;
    text-transform: uppercase;
    padding: 0 10px;
    background-position: left 10px center;
    position: relative;
    font-weight: 500;
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    min-height: 33px;
    z-index: 1;

    i {
      font-weight: bold;
    }
  }

  button:hover {
    cursor: pointer;
  }

  ul.options {
    width: 118px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    border: 1px solid black;
    font-size: 10px;
    display: none;
    list-style: none;
    padding: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    transform: translateY(100%);
    z-index: 2;
  }

  ul.show {
    display: block;
  }

  ul.options li {
    padding: 5px;
  }

  ul.options li:active,
  ul.options li:focus,
  ul.options li:hover,
  ul.options li[aria-selected="true"] {
    background: $gray;
    color: #fff;
    cursor: pointer;
  }
}
