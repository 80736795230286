.btn-success {
  @include button-variant(
    $success,
    darken($success, 7.5%),
    white,
    lighten($success, 5%),
    lighten($success, 10%),
    white
  );
}
.btn-outline {
  @include button-outline-variant(black);
  &:active,
  &.active,
  &.dropdown.show {
    color: white;
    background-color: black;
    border-color: black;
  }
}
.btn-outline-success {
  @include button-outline-variant($success, white, darken($success, 7.5%), $success, white);
  &:hover {
    background-color: $success;
  }
}

@mixin after-icon-right($image, $height, $width, $right) {
  content: "";
  position: absolute;
  right: $right;
  bottom: 50%;
  transform: translateY(50%);
  height: $height;
  width: $width;
  background: url($image) no-repeat;
  background-size: contain;
  transition: 0.2s;
}
@mixin after-icon-left($image, $height, $width, $left) {
  content: "";
  position: absolute;
  left: $left;
  bottom: 50%;
  transform: translateY(50%);
  height: $height;
  width: $width;
  background: url($image) no-repeat;
  background-size: contain;
  transition: 0.2s;
}
@mixin custom-table-header-border($top, $text) {
  position: relative;
  &::after {
    position: absolute;
    content: "#{$text}";
    top: $top;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid black;
    width: calc(100% + 2px);
    height: 48px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 0px;
    left: -1px;
  }
}
@mixin custom-table-header($top) {
  position: relative;
  &::after {
    position: absolute;
    content: attr(data-title);
    top: $top;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
@mixin custom-table-after($bottom, $text) {
  position: relative;
  &::after {
    position: absolute;
    content: "#{$text}";
    bottom: $bottom;
    font-size: 10px;
    font-weight: bold;
  }
}
@mixin element-bg($justify-content) {
  height: 35px;
  display: flex;
  justify-content: $justify-content;
  align-items: center;
}
@mixin center-all-above {
  position: relative;
  * {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}
@mixin center-element {
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
}
@mixin paddings-x-25{
  padding-left: 25px;
  padding-right: 25px;
}