@import "./variables.scss";
@media (max-width: 768px) {
  @mixin margin-lower-part {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  // .product-list {
  .product-wrapper {
    .image-col {
      flex-basis: 34%;
    }

    .product-description {
      flex-basis: 65%;
    }

    .price-wrapper {
      flex-basis: 38%;
      align-items: center;
      margin-left: 0px !important;
      margin-top: 20px;
    }

    .counter-cart-wrapper {
      flex-basis: 50%;
      width: initial;
      @include margin-lower-part;
      margin-left: auto;

      .add-product {
        margin-top: 3px;
        margin-right: 0px;
        width: 275px;
      }
    }
  }
  // }
  .nav-tabs {
    gap: 15px;
    flex-wrap: wrap;
    border-bottom: 0px;

    .nav-item {
      border-radius: 20px;
      border: 0px;
      background-color: #e5eaef;
      text-transform: uppercase;
      font-weight: bold;

      button {
        background-color: transparent;
        border-radius: 20px;
      }
    }
  }
  .product-page .product-page-content {
    width: 100%;
    max-width: initial;
  }
  .accordion .accordion-swiper-container .swiper-slide a:first-of-type {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;

    img {
      position: absolute;
      height: 100% !important;
    }
  }

}
